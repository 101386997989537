import React, { useState } from 'react';
import { List, Filter, Datagrid, DateInput, BooleanField, TextInput, TextField, BooleanInput, TabbedForm, FormTab, Edit, Create, SimpleForm, EditButton, ArrayInput, SimpleFormIterator, Pagination, minLength, Button } from 'react-admin';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import { useNotify, useRecordContext } from 'react-admin';
import { functions, httpsCallable } from './firebase';

const useFirebaseCall = (userId) => {
  const notify = useNotify();
  const record = useRecordContext();

  const handleFirebaseCall = async () => {
    if (!userId) {
      notify('Please enter a user ID', 'warning');
      return;
    }

    const currentUserId = record.id;
    const copyDB = httpsCallable(functions, 'copyDB');
    try {
      const result = await copyDB({
        clientId: userId,
        targetClientId: currentUserId
      });

      if (result.data.success) {
        notify('Copie OK', 'info');
        return result.data;
      } else {
        notify('Erreur: ' + result.data.error, 'error');
        return null;
      }
    } catch (error) {
      console.error('Error executing Firebase function:', error);
      notify('Erreur: ' + error.message, 'error');
      return null;
    }
  };

  return handleFirebaseCall;
};

const FirebaseFunctionButton = () => {
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const handleInputChange = (event) => {
    setUserId(event.target.value);
  };
  const handleFirebaseCall = useFirebaseCall(userId);

  const handleClick = async () => {
    setLoading(true);
    try {
      await handleFirebaseCall();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        value={userId}
        label={'Licence'}
        onChange={handleInputChange}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'COPIER LICENCE'}
      </Button>
    </div>
  );
};

const MyNameField = ({ source }) => {
  const record = useRecordContext();
  return (
    <span>
      {(record[source] && record[source][record[source].length - 1]) ? record[source][record[source].length - 1].name : '--'}
    </span>
  )
}

const MyPostalField = ({ source }) => {
  const record = useRecordContext();
  return (
    <span>
      {(record[source] && record[source][record[source].length - 1]) ? record[source][record[source].length - 1].postal : '--'}
    </span>
  )
}

const MyDateField = ({ source }) => {
  const record = useRecordContext();
  return (
    <span>
      {record[source] ? moment(record[source]).format('DD-MM-YYYY H:mm') : '--'}
    </span>
  )
}

const ErorrField = ({ source }) => {
  const record = useRecordContext();
  return (
    <span>
      {record[source] ? 'Oui' : '--'}
    </span>
  )
}

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
  </Filter>
);

const UserPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;

export const UserList = props => (
  <List filters={<UserFilter />}{...props} bulkActionButtons={false} pagination={<UserPagination />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <MyNameField source="ticketHeader" label={'Nom'} />
      <MyPostalField source="ticketHeader" label={'CP'} />
      <BooleanField source="leasing.enabled" label={'Abo'} />
      <BooleanField source="certificate.c2024.paid" label={'C 2024'} />
      <BooleanField source="certificateUpdate.c2024" label={'MAJ 2024'} />
      <BooleanField source="certificate.c2025.paid" label={'C 2025'} />
      <BooleanField source="certificateUpdate.c2025" label={'MAJ 2025'} />
      <BooleanField source="connected" label={'Connecté ?'} />
      <MyDateField source="lastBackup" label={'Sauvegarde'} />
      <ErorrField source="createdAt" label={'Erreur'} />
      <EditButton />
    </Datagrid>
  </List>
);


const UserTitle = () => {
  const record = useRecordContext();
  return <span>Licence {`${record.id} ( ${(record.ticketHeader && record.ticketHeader[record.ticketHeader.length - 1]) ? record.ticketHeader[record.ticketHeader.length - 1].name : '-'} )`}</span>;
}

export const UserEdit = props => (
  <Edit title={<UserTitle />} {...props}>
    <TabbedForm>
      <FormTab label="KBIS">
        <ArrayInput source="ticketHeader">
          <SimpleFormIterator disableRemove inline>
            <TextInput source="id" label="ID" />
            <TextInput source="name" label="NOM" defaultValue={''} />
            <TextInput source="companyName" label="Raison social" defaultValue={''} />
            <TextInput source="address" label="Adresse" defaultValue={''} />
            <TextInput source="city" label="Ville" defaultValue={''} />
            <TextInput source="postal" label="CP" defaultValue={''} />
            <TextInput source="country" label="Pays" defaultValue={''} />
            <TextInput source="phone" label="TEL" defaultValue={''} />
            <TextInput source="siret" label="SIRET" defaultValue={''} />
            <TextInput source="vat" label="TVA" defaultValue={''} />
            <TextInput source="ape" label="Code NAF" defaultValue={''} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>


      <FormTab label="C24" path="certificat2024">
        <BooleanInput source="certificate.c2024.paid" label="Activé" defaultValue={false} />
        <DateInput source="certificate.c2024.shouldPaidBefore" label="should" format={v => v instanceof Date ? moment(v).format('YYYY-MM-DD').toString() : moment(v?.toDate()).format('YYYY-MM-DD').toString()} parse={v => new Date(moment(v))} defaultValue={moment({ hour: 1 })?.toDate()} />
      </FormTab>

      <FormTab label="C25" path="certificat2025">
        <BooleanInput source="certificate.c2025.paid" label="Activé" defaultValue={false} />
        <DateInput source="certificate.c2025.shouldPaidBefore" label="should" format={v => v instanceof Date ? moment(v).format('YYYY-MM-DD').toString() : moment(v?.toDate()).format('YYYY-MM-DD').toString()} parse={v => new Date(moment(v))} defaultValue={moment({ hour: 1 })?.toDate()} />
      </FormTab>

      <FormTab label="Co" path="connection">
        <BooleanInput source="connected" label="Déjà connecté ?" />
      </FormTab>

      <FormTab label="Abo" path="abonnement">
        <BooleanInput source="leasing.enabled" label="Activé" />
        <BooleanInput source="leasing.expired" label="Expriré" />
      </FormTab>

      <FormTab label="RAZ" path="raz">
        <BooleanInput source="raz.enabled" label="Activé" />
        <TextInput source="raz.password" label="Mot de passe" />
      </FormTab>

      <FormTab label="M" path="maintenance">

        <BooleanInput source="restore.enabled" label="Activé" />
        <TextInput source="restore.password" label="Mot de passe" />

      </FormTab>

      <FormTab label="Teleco" path="telecommandes">

        <ArrayInput source="orderMan">
          <SimpleFormIterator disableRemove disableAdd disableReordering inline>
            <TextField source="deviceId" label="Numero" />
            {/* <TextInput source="deviceId" label="Numero" /> */}
            <TextInput source="uniqueId" label="Id unique" defaultValue={null} />
            <TextInput source="badgeSerial" label="Lecteur de badge" defaultValue={null} />
            <BooleanInput source="enabled" label="Activé" defaultValue={false} />
            <BooleanInput source="paid" label="Payé" defaultValue={false} />
            <DateInput source="enabledUntil" label="Activé jusqu'au" defaultValue={null} />
          </SimpleFormIterator>
        </ArrayInput>

      </FormTab>

      <FormTab label="KDS" path="kds">

        <ArrayInput source="kds">
          <SimpleFormIterator disableRemove disableReordering inline>
            <TextField source="deviceId" label="Numero" />
            <TextInput source="uniqueId" label="Id unique" />
            <BooleanInput source="enabled" label="Activé" />
            <BooleanInput source="paid" label="Payé" />
            <DateInput source="enabledUntil" label="Activé jusqu'au" format={v => v instanceof Date ? moment(v).format('YYYY-MM-DD').toString() : moment(v?.toDate()).format('YYYY-MM-DD').toString()} parse={v => new Date(moment(v))} initialValue={moment({ hour: 1 }).toDate()} defaultValue={null} />
          </SimpleFormIterator>
        </ArrayInput>

      </FormTab>

      <FormTab label="Aff" path="afficheur-client">
        <BooleanInput source="customerDisplay.enabled" label="Activé" />
      </FormTab>

      <FormTab label="Badge" path="lecteur-badge">
        <BooleanInput source="badge.enabled" label="Activé" />
        <TextInput source="badge.serialNumber" label="Numero de serie" defaultValue={null} />
      </FormTab>

      <FormTab label="mdp" path="password">
        <TextField source="password.cloture" label="MDP Cloture" />
        <TextField source="password.user" label="MDP Gerant" />
      </FormTab>

      <FormTab label="mdp perso" path="auth-password">
        <TextInput source="auth.password" label="Mot de passe" validate={minLength(8)} defaultValue={null} />
      </FormTab>

      <FormTab label="Copie licence">
        <FirebaseFunctionButton />
      </FormTab>

    </TabbedForm >
  </Edit >
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="id" />
    </SimpleForm>
  </Create>
);